import { identifyUser, trackEvent } from '../utils/analytics'
import {
  SET_ACCOUNT_DATA,
  CLEAR_ACCOUNT_DATA,
  SET_ACCOUNT_SHIPPING_ADDRESS,
  SET_ACCOUNT_BILLING_ADDRESS,
  SET_ACCOUNT_PERSONAL_INFO,
  GET_USER_IN_PROGRESS,
  GET_USER_ERROR,
  SET_ACCOUNT_PAYMENT_METHOD,
  UNSUBSCRIBE_START,
  UNSUBSCRIBE_SUCCESS,
  USER_NOT_FOUND,
  SET_ACCOUNT_ERROR,
  CLEAR_ACCOUNT_ERROR,
  GET_RESULTS,
  GET_REFERRAL_DASHBOARD,
  GET_ORDER_HISTORY,
  GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_IN_PROGRESS,
  GET_SUBSCRIPTION_ORDERS_IN_DRAFT_SUCCESS,
  GET_SUBSCRIPTION_ORDERS_IN_DRAFT_ERROR,
  SET_USER_BLUEPRINT_CHART,
  SET_LOYALTY_PROGRAM,
  SET_ACCOUNT_INFO,
  SET_ACCOUNT_PREFERENCE,
  SET_ACCOUNT_FEEDBACK,
  SET_ACCOUNT_COMPLETE_QUIZ,
  CREATE_NOTIFICATION,
  SET_LATEST_SETS,
  GET_EYE_CREAM_QUIZ_RESULTS,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_ERROR,
  GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_SUCCESS,
  SET_MOST_RECENT_QUIZ_ID,
  GET_SERUM_RESULTS,
  SET_SEPHORA_STORE_ID,
  SET_SEPHORA_ADVISOR_NAME,
  SET_LEAD_EMAIL,
  GET_SUBSCRIPTION_ORDERS_IN_PENDING_SUCCESS,
  GET_SUBSCRIPTION_ORDERS_IN_PENDING_ERROR,
  SET_ORDER_DETAILS
} from '../constants/actionTypes'
import provenApi from 'services/proven-api'
import provenPay from 'services/proven-pay'
import { createNotification, setAppMessage } from './app.actions'
import { setAuthError } from './auth.actions'
import {
  userUrl,
  unsubscribeUrl,
  sendReferralUrl,
  referralDashboardUrl,
  orderHistoryUrl,
  orderDetailsUrl,
  verifyAddressUrl,
  userPaymentInfo,
  userPreferenceUrl,
  feedbackUrl,
  redeemGiftCardInFullUrl,
  updateUserPaymentInfo,
  quizResultsUrl,
  serumResultsUrl,
  storeFieldsUserUrl
} from '../constants/endpoints'
import {
  convertSubscriptionItemsToNonSystemItems,
  convertSubscriptionItemsToPayload,
  isEyeCreamSubscription,
  isSystemSubscription,
  validateZip
} from '../utils/helpers'
import { subscriptionsSelect } from '../utils/selectors'
import { cloneDeep } from 'lodash'
import { loadShopSubscription } from './shop.actions'
import { AWAITING_PAYMENT, DRAFT } from '../constants/order-status'

const getSetAccountDataAction = account => ({
  type: SET_ACCOUNT_DATA,
  account
})

export const getResults = variant => async dispatch => {
  const getResultsUrl = variant ? `${quizResultsUrl}/v4?variant=${variant}` : `${quizResultsUrl}/v4`
  const { data } = await provenApi.get(getResultsUrl)
  dispatch({ type: GET_RESULTS, data })
  return data
}

export const getEyeCreamQuizResults = () => async dispatch => {
  const { data } = await provenApi.get(`${quizResultsUrl}/eye-cream/v2`)
  dispatch({ type: GET_EYE_CREAM_QUIZ_RESULTS, data })
  return data
}

export const getSerumResults = () => async (dispatch, getState) => {
  const { data } = await provenApi.get(serumResultsUrl)
  //const { data } = await get(getState)
  dispatch({ type: GET_SERUM_RESULTS, data })
  return data
}

export const hasUserPurchased = () => async dispatch => {
  const { data } = await provenApi.get(`${userUrl}/has-purchased`)
  dispatch(setAccountPersonalInfo({ hasPurchased: !!data }))
  return !!data
}

export const setAccountData = data => dispatch => {
  dispatch(getSetAccountDataAction(data))
}

export const clearAccountData = () => ({
  type: CLEAR_ACCOUNT_DATA
})

export const setAccountShippingAddress = shippingAddress => ({
  type: SET_ACCOUNT_SHIPPING_ADDRESS,
  shippingAddress
})

export const setAccountPersonalInfo = data => {
  return {
    type: SET_ACCOUNT_PERSONAL_INFO,
    data
  }
}

export const setMostRecentQuizId = id => dispatch => {
  dispatch({
    type: SET_MOST_RECENT_QUIZ_ID,
    id
  })
}

export const setReferralDashboardData = data => {
  return {
    type: GET_REFERRAL_DASHBOARD,
    referrals: data.result
  }
}

export const setOrderHistory = data => {
  return {
    type: GET_ORDER_HISTORY,
    orders: data.result
  }
}

const setAccountPaymentMethod = data => ({
  type: SET_ACCOUNT_PAYMENT_METHOD,
  data
})

export const updateAccountInfo = userInfo => dispatch => {
  dispatch(setAccountPersonalInfo(userInfo))
}

export const updateAccountPaymentMethod = data => dispatch => {
  dispatch(setAccountPaymentMethod(data))
}

export const getCustomerPaymentInfo = () => async dispatch => {
  const { data } = await provenPay.get(userPaymentInfo)
  dispatch(setAccountPaymentMethod(data))
}

export const setSephoraStoreId = sephoraStoreId => {
  return {
    type: SET_SEPHORA_STORE_ID,
    sephoraStoreId
  }
}

export const setSephoraAdvisorName = sephoraAdvisorName => {
  return {
    type: SET_SEPHORA_ADVISOR_NAME,
    sephoraAdvisorName
  }
}

export const setLeadEmail = leadEmail => {
  return {
    type: SET_LEAD_EMAIL,
    leadEmail
  }
}

export const updateCustomerPaymentInfo =
  ({ chargebeeToken, paypalNonce, billingAddress }) =>
  async dispatch =>
    provenPay
      .post(updateUserPaymentInfo, {
        chargebeeToken,
        paypalNonce,
        billingAddress
      })
      .then(({ data: { card } }) => {
        dispatch(setAccountPaymentMethod(card))
        dispatch(updateAccountBillingAddress(billingAddress))
      })

const updateAccountShippingAddress = shippingAddress => dispatch => {
  let address = { ...shippingAddress }

  if (address.name) {
    const [firstName, lastName] = address.name.split(' ')
    delete address.name
    address = {
      ...address,
      firstName,
      lastName
    }
  }

  dispatch(setAccountShippingAddress(address))
}

export const updateAccountBillingAddress = billingAddress => dispatch => {
  dispatch({
    type: SET_ACCOUNT_BILLING_ADDRESS,
    billingAddress
  })
}

export const updateAccountAndQuizState = (data, identify, doNotUpdateAccountInfo) => dispatch => {
  dispatch(clearAccountData())
  identify && identifyUser()
  const { user } = data.result
  const {
    isPendingChargebeeMigration,
    info,
    shippingAddress,
    referrerId,
    orders,
    isAdmin,
    roles,
    completedEyeQuiz,
    completedSkinQuiz,
    completedSerumQuiz,
    countryCode,
    _id,
    source
  } = user
  const hasPurchased = orders && orders.length > 0 ? true : false
  const hasThankYouOffer =
    orders && orders.length > 0
      ? orders.filter(o => {
          return (
            o.subscription &&
            o.subscription.specialOffer &&
            o.subscription.specialOffer === 'THANK_YOU'
          )
        }).length > 0
      : false
  dispatch(setAccountData(user))

  const accountInfo = {
    ...info,
    shopifyId: user.shopifyId,
    magicLinkToken: user.info.magicLinkToken,
    isPendingChargebeeMigration,
    referrerId,
    hasPurchased,
    hasThankYouOffer,
    isAdmin: !!isAdmin,
    roles,
    countryCode,
    _id,
    source
  }
  if (!doNotUpdateAccountInfo) {
    dispatch(updateAccountInfo(accountInfo))
  }

  dispatch(
    dispatch(
      setAccountCompleteQuiz({
        completedEyeQuiz,
        completedSkinQuiz,
        completedSerumQuiz
      })
    )
  )
  dispatch(updateAccountShippingAddress(shippingAddress))
  return accountInfo
}

export const getUserDetails = (identify, doNotUpdateAccountInfo) => async dispatch => {
  dispatch({ type: GET_USER_IN_PROGRESS })
  try {
    const { data } = await provenApi.get(userUrl)
    const accountInfo = dispatch(updateAccountAndQuizState(data, identify, doNotUpdateAccountInfo))
    return {
      ...data.result.user.info,
      orders: data.result.orders,
      isAdmin: data.result.user.isAdmin,
      roles: data.result.user.roles,
      completedSkinQuiz: data.result.user.completedSkinQuiz,
      completedEyeQuiz: data.result.user.completedEyeQuiz,
      accountInfo: { ...accountInfo, createdAt: data.result.user.createdAt }
    }
  } catch (response) {
    dispatch({ type: GET_USER_ERROR })
    trackEvent('get user details failed')
    if (response.data?.message === 'USER_NOT_FOUND') {
      dispatch({ type: USER_NOT_FOUND })
      throw dispatch(setAuthError('User not found'))
    } else {
      throw dispatch(setAuthError(response.data?.message))
    }
  }
}

export const checkUserAuth = () => async () => {
  return provenApi.get(userUrl + '/auth')
}

export const getUserBlueprintChartData = () => async dispatch => {
  try {
    const { data } = await provenApi.get('user/charts/blueprint')
    dispatch({ type: SET_USER_BLUEPRINT_CHART, data })
  } catch ({ response }) {
    trackEvent('get blueprint chart failed')
  }
}

// TODO to deprecate
export const updateSubscription = subscription => async (/*dispatch*/) => {
  try {
    const { data } = await provenApi.put('products/subscribe', subscription)
    /*    const reduxData = {
      nextChargeDate: data.nextChargeDate,
      plans: data.plans,
      subscriptionId: data.subscriptionId,
      summary: data.summary
    }*/
    //dispatch({ type: SET_MOST_RECENT_SUBSCRIPTION, data: reduxData })
    // const { data } = await provenApi.put('products/subscribe', subscription);
    return data
  } catch ({ response }) {
    return { message: 'We could not update your subscription.' }
  }
}

export const delaySubscription = async (id, data, options) => {
  const { data: subscription } = await provenPay.put(`subscription/delay/${id}`, data, options)
  return subscription
}

const updateSubscriptionsLists = subscription => (dispatch, getState) => {
  const store = getState()
  const { subscriptions } = store.account
  const newSubscriptionList = subscriptions.map(sub =>
    sub.id === subscription.id ? prepareSubscription(subscription, store) : sub
  )
  dispatch({
    type: GET_SUBSCRIPTIONS,
    subscriptions: newSubscriptionList
  })
}

// This action should be used to modify all Subscriptions
export const modifySubscriptionById =
  ({ subscriptionId, items, nextBillingDate, oneTimeItems, coupon, frequency }) =>
  async (dispatch, getState) => {
    const { subscriptions } = getState().account
    const subscription = subscriptions.find(sub => sub.id === subscriptionId)
    const options = {
      nextBillingDate,
      items,
      oneTimeItems,
      status: subscription.status,
      coupon,
      frequency
    }

    const { data: updatedSubscription } = await provenPay.put(
      `subscription/${subscriptionId}`,
      options
    )

    dispatch(updateSubscriptionsLists(updatedSubscription))

    // update subscription details for Shop TODO Shop should rely on activeSubscription, remove after PD-1658
    const isEyeSub = isEyeCreamSubscription(subscription)
    if (isEyeSub) dispatch(loadShopSubscription())

    return updatedSubscription
  }

export const deleteSubscription = async subscription => {
  try {
    return provenApi.delete(`products/subscribe?subscriptionId=${subscription.subscriptionId}`)
  } catch ({ response }) {
    return { message: 'We could not delete your subscription.' }
  }
}

export const verifyAddress = async address => {
  try {
    if (address) {
      if (address.zip && address.country) {
        const isZipValid = validateZip(address.zip, address.country)
        if (!isZipValid) return { message: 'Invalid zip' }
      }
      const { data } = await provenApi.post(verifyAddressUrl, { address })
      return data
    }
  } catch ({ response }) {
    return {
      message: 'We could not verify your address. Please try again.'
    }
  }
}

export const getReferralDashboard = () => async dispatch => {
  dispatch({ type: GET_USER_IN_PROGRESS })
  try {
    const { data } = await provenApi.get(referralDashboardUrl)
    dispatch(setReferralDashboardData(data))
  } catch ({ response }) {
    dispatch({ type: GET_USER_ERROR })
    trackEvent('get user details failed')
    if (response.data.message === 'USER_NOT_FOUND') {
      dispatch({ type: USER_NOT_FOUND })
      throw dispatch(setAuthError('User not found'))
    } else {
      throw dispatch(setAuthError(response.data.message))
    }
  }
}

export const getOrderHistory = () => async dispatch => {
  try {
    const { data } = await provenApi.get(orderHistoryUrl(0))
    dispatch(setOrderHistory(data))
  } catch ({ response }) {
    console.error('get order history failed')
    trackEvent('get order history failed')
  }
}

export const getSubscriptionOrdersInDraft = () => async dispatch => {
  try {
    const { data } = await provenApi.get(orderHistoryUrl(0, [DRAFT], true))
    dispatch({ type: GET_SUBSCRIPTION_ORDERS_IN_DRAFT_SUCCESS, payload: { orders: data.result } })
    return data
  } catch (error) {
    trackEvent('get subscription orders in Draft failed')
    dispatch({ type: GET_SUBSCRIPTION_ORDERS_IN_DRAFT_ERROR, payload: { error } })
  }
}

export const getSubscriptionOrdersInDraftOrAwaitingPayment = () => async dispatch => {
  try {
    dispatch({
      type: GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_IN_PROGRESS
    })
    const { data } = await provenApi.get(orderHistoryUrl(0, [DRAFT, AWAITING_PAYMENT], true))
    dispatch({
      type: GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_SUCCESS,
      payload: { orders: data.result }
    })
    return data
  } catch (error) {
    trackEvent('get subscription orders in Draft or Awaiting Payment failed')
    dispatch({
      type: GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_ERROR,
      payload: { error }
    })
  }
}

export const getSubscriptionOrdersInPending = () => async dispatch => {
  try {
    const { data } = await provenApi.get(orderHistoryUrl(0, [], true))
    dispatch({ type: GET_SUBSCRIPTION_ORDERS_IN_PENDING_SUCCESS, payload: { orders: data.result } })
    return data
  } catch (error) {
    trackEvent('get subscription orders in Pending failed')
    dispatch({ type: GET_SUBSCRIPTION_ORDERS_IN_PENDING_ERROR, payload: { error } })
  }
}

export const getProductPlans = (plantype = 'all', active_or_inactive = 'active') => {
  return provenApi.get(`products?plantype=${plantype}&active_or_inactive=${active_or_inactive}`)
}

export const getSubscription = async options => {
  const { data } = await provenPay.get('subscription/list', options)
  return data?.find(s => s.priceVersion !== 'shop-v1') // system subscription
}

const prepareSubscription = (subscription, store) => {
  if (!subscription) return null

  const isEyeCreamSub = isEyeCreamSubscription(subscription)

  if (!subscription.products) {
    if (isEyeCreamSub) {
      subscription.products = store.shop.shopProducts
    } else {
      subscription.products = store.product.items
    }
  }

  return { ...subscription }
}

export const getUserSubscriptions = () => async (dispatch, getState) => {
  const validStatuses = ['active', 'future', 'in_trial', 'cancelled', 'paused']
  const searchParams = new URLSearchParams()
  validStatuses.forEach(status => searchParams.append('status', status))
  const request = { params: searchParams }
  return provenPay
    .get('subscription/list', request)
    .then(({ data: subscriptions }) => {
      const preparedSubscriptions = subscriptions.map(s => prepareSubscription(s, getState()))

      dispatch({
        type: GET_SUBSCRIPTIONS,
        subscriptions: preparedSubscriptions
      })
      return preparedSubscriptions
    })
    .catch(() => {
      return { message: 'We could not get your subscriptions.' }
    })
}

export const getLoyaltyProgram = () => async dispatch => {
  try {
    const res = await provenApi.get('products/loyalty-program')
    dispatch({ type: SET_LOYALTY_PROGRAM, data: res.data })
    return res
  } catch ({ response }) {
    return { message: response }
  }
}

export const getOrderDetails = orderId => async dispatch => {
  const orderDetails = await provenApi.get(orderDetailsUrl(orderId))
  dispatch({
    type: SET_ORDER_DETAILS,
    data: orderDetails.data
  })
  return orderDetails
}

export const getAccountInfo = () => async dispatch => {
  const { data: credit } = await provenPay.get('customer/credit')
  dispatch({
    type: SET_ACCOUNT_INFO,
    data: { credit }
  })
  return credit
}

export const patchStoreFieldsUser = (email, data) => () => {
  return provenApi.put(`${storeFieldsUserUrl}/${email}`, data)
  // .then(() => {
  //   //console.log(`IDENTITY: account.actions - patchUser`)
  //   identifyUser()
  // })
  // .catch(err => {
  //   trackEvent('save account userinfo failed')
  //   // dispatch(setAppMessage(err.response.data.message));
  //   throw err
  // })
}

export const patchUser = user => () => {
  return provenApi
    .put(userUrl, user)
    .then(() => {
      //console.log(`IDENTITY: account.actions - patchUser`)
      identifyUser()
    })
    .catch(err => {
      trackEvent('save account userinfo failed')
      // dispatch(setAppMessage(err.response.data.message));
      throw err
    })
}

export const updatePhone = async (phone, doubleOptInPhone, verifiedPhoneNumber) => {
  try {
    let params = {
      phone,
      doubleOptInPhone,
      verifiedPhoneNumber
    }

    const url = '/user/phone'

    const { data } = await provenApi.put(url, params)
    return data
  } catch (error) {
    throw new Error(error.data.message)
  }
}

export const sendReferrals = referralEmails => async dispatch => {
  let payload = { emails: referralEmails }
  try {
    await provenApi.post(sendReferralUrl, payload)
    dispatch(createNotification('Success:', 'Yor referral emails were sent'))
    dispatch(getReferralDashboard())
  } catch (e) {
    trackEvent('send referrals failed')
    dispatch(createNotification('Error:', "We couldn't send your referral emails"))
  }
}

const startUnsubscribe = () => dispatch => {
  dispatch({
    type: UNSUBSCRIBE_START
  })
}

export const unsubscribe = () => dispatch => {
  dispatch(startUnsubscribe())
  return provenApi
    .post(unsubscribeUrl)
    .then(() => {
      dispatch({
        type: UNSUBSCRIBE_SUCCESS
      })
    })
    .catch(({ response }) => {
      throw dispatch(setAppMessage(response.data.message))
    })
}

export const setAccountError = error => dispatch => {
  dispatch({
    type: SET_ACCOUNT_ERROR,
    error
  })
}

export const clearAccountError = () => dispatch => {
  dispatch({
    type: CLEAR_ACCOUNT_ERROR
  })
}

export const setPreference = preference => async dispatch => {
  const { data } = await provenApi.put(userPreferenceUrl, preference)
  dispatch({
    type: SET_ACCOUNT_PREFERENCE,
    data: data
  })
  return data
}

export const setFeedback = answers => async dispatch => {
  const { data } = await provenApi.post(feedbackUrl, answers)
  dispatch({
    type: SET_ACCOUNT_FEEDBACK,
    data: data
  })
  return data
}

export const getLatestSets = () => async dispatch => {
  try {
    const { data } = await provenApi.get('latest-sets/v2')
    dispatch({
      type: SET_LATEST_SETS,
      data
    })
    return data
  } catch (error) {
    dispatch({
      type: CREATE_NOTIFICATION,
      key: new Date().getTime() + Math.random(),
      strong: 'Warning:',
      text: 'There are no upcoming sets.'
    })
    return error
  }
}

export const redeemGiftCard = () => async (dispatch, getState) => {
  const { giftCard } = getState().checkout
  if (giftCard) {
    provenPay.post(redeemGiftCardInFullUrl, { code: giftCard })
  }
}

export const cancelSubscription = subscriptionId => {
  return provenPay.put(`subscription/cancel/${subscriptionId}`).catch(err => {
    // eslint-disable-next-line no-console
    console.error(err)
    return { message: 'We could not cancel your subscription.' }
  })
}

export const pauseSubscription = subscriptionId => {
  return provenPay.put(`subscription/pause/${subscriptionId}`).catch(err => {
    // eslint-disable-next-line no-console
    console.error(err)
    return { message: 'We could not pause your subscription.' }
  })
}

export const resumeSubscription = subscriptionId => {
  return provenPay.put(`subscription/resume/${subscriptionId}`).catch(err => {
    // eslint-disable-next-line no-console
    console.error(err)
    return { message: 'We could not resume your subscription.' }
  })
}

export const getSubscriptionDetails = async ({ subscriptionId, items, oneTimeItems, coupon }) => {
  const payload = {
    subscriptionId: subscriptionId,
    items: items && convertSubscriptionItemsToPayload(items),
    oneTimeItems: oneTimeItems && convertSubscriptionItemsToPayload(oneTimeItems),
    coupon
  }

  return provenPay
    .post('get-subscription-details', payload)
    .then(({ data: subscriptionDetails }) => {
      return subscriptionDetails
    })
    .catch(() => {
      return { message: 'We could not get details of your subscription.' }
    })
}

export const setAccountCompleteQuiz = data => {
  return {
    type: SET_ACCOUNT_COMPLETE_QUIZ,
    data: {
      completedEyeQuiz: data.completedEyeQuiz,
      completedSkinQuiz: data.completedSkinQuiz,
      completedSerumQuiz: data.completedSerumQuiz
    }
  }
}
